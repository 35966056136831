@import "fonts";

$font__headings: 'SPORE', sans-serif;

.site-title,
.content-title,
h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

@import "steve-for-jekyll";

.archive-section {
  margin-top: calc(var(--gap) * 2);

  .archive-title {
    margin-bottom: var(--gap);
  }

  .tags-list {
    list-style: none;
    margin-left: 0;

    li {
      display: inline-block;
      line-height: 1.7;

      .tag-count {
        font-size: 0.875rem;
      }

      &:not(:last-child) {
        &::after {
          font-size: 0.875rem;
          content: "/";
          padding-left: 5px;
          padding-right: 5px;
          color: var(--color-details);
        }
      }
    }
  }
}

body {
  background-image: url("/assets/images/bg1.jpg");
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
}

.stats-title {
  font-weight: normal;
}

.stats-list {
  margin-left: 0;
  list-style: none;
  margin-top: calc(var(--gap) / 2);
  margin-bottom: calc(var(--gap) / 2);
  font-size: 0.875rem;

  li {
    padding-top: calc(var(--gap) / 2);
    padding-bottom: calc(var(--gap) / 2);
    border-top: 1px solid var(--color-details-lighter);

    &:last-child {
      border-bottom: 1px solid var(--color-details-lighter);
    }
  }
}

.stats-credits {
  font-size: 0.75rem;
}

.colophon,
.colophon .external-links .link a {
  color: var(--color-bg);
  text-shadow: 2px 2px 2px var(--color-details-darker);
}

// Code
//
// Inline and block-level code snippets. Includes tweaks to syntax highlighted
// snippets from Pygments/Rouge and Gist embeds.

code,
pre {
  font-family: var(--font-code);
}

code {
  font-size: 85%;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: var(--spacer-3);
  overflow: auto;
}

.highlight {
  padding: var(--spacer);
  margin-bottom: var(--spacer);
  background-color: var(--code-bg);
  border-radius: var(--border-radius);

  pre {
    margin-bottom: 0;
  }

  // Triple backticks (code fencing) doubles the .highlight elements
  .highlight {
    padding: 0;
  }
}

.rouge-table {
  margin-bottom: 0;
  font-size: 100%;

  &,
  td,
  th {
    border: 0;
  }

  .gutter {
    vertical-align: top;
    user-select: none;
    opacity: 0.25;
  }
}

// Gist via GitHub Pages
.gist .markdown-body {
  padding: 15px !important;
}

.highlight .hll {
  background-color: #ffc;
}
.highlight .c {
  color: #999;
} /* Comment */
.highlight .err {
  color: #a00;
  background-color: #faa;
} /* Error */
.highlight .k {
  color: #069;
} /* Keyword */
.highlight .o {
  color: #555;
} /* Operator */
.highlight .cm {
  color: #09f;
  font-style: italic;
} /* Comment.Multiline */
.highlight .cp {
  color: #099;
} /* Comment.Preproc */
.highlight .c1 {
  color: #999;
} /* Comment.Single */
.highlight .cs {
  color: #999;
} /* Comment.Special */
.highlight .gd {
  background-color: #fcc;
  border: 1px solid #c00;
} /* Generic.Deleted */
.highlight .ge {
  font-style: italic;
} /* Generic.Emph */
.highlight .gr {
  color: #f00;
} /* Generic.Error */
.highlight .gh {
  color: #030;
} /* Generic.Heading */
.highlight .gi {
  background-color: #cfc;
  border: 1px solid #0c0;
} /* Generic.Inserted */
.highlight .go {
  color: #aaa;
} /* Generic.Output */
.highlight .gp {
  color: #009;
} /* Generic.Prompt */
.highlight .gs {
} /* Generic.Strong */
.highlight .gu {
  color: #030;
} /* Generic.Subheading */
.highlight .gt {
  color: #9c6;
} /* Generic.Traceback */
.highlight .kc {
  color: #069;
} /* Keyword.Constant */
.highlight .kd {
  color: #069;
} /* Keyword.Declaration */
.highlight .kn {
  color: #069;
} /* Keyword.Namespace */
.highlight .kp {
  color: #069;
} /* Keyword.Pseudo */
.highlight .kr {
  color: #069;
} /* Keyword.Reserved */
.highlight .kt {
  color: #078;
} /* Keyword.Type */
.highlight .m {
  color: #f60;
} /* Literal.Number */
.highlight .s {
  color: #d44950;
} /* Literal.String */
.highlight .na {
  color: #4f9fcf;
} /* Name.Attribute */
.highlight .nb {
  color: #366;
} /* Name.Builtin */
.highlight .nc {
  color: #0a8;
} /* Name.Class */
.highlight .no {
  color: #360;
} /* Name.Constant */
.highlight .nd {
  color: #99f;
} /* Name.Decorator */
.highlight .ni {
  color: #999;
} /* Name.Entity */
.highlight .ne {
  color: #c00;
} /* Name.Exception */
.highlight .nf {
  color: #c0f;
} /* Name.Function */
.highlight .nl {
  color: #99f;
} /* Name.Label */
.highlight .nn {
  color: #0cf;
} /* Name.Namespace */
.highlight .nt {
  color: #2f6f9f;
} /* Name.Tag */
.highlight .nv {
  color: #033;
} /* Name.Variable */
.highlight .ow {
  color: #000;
} /* Operator.Word */
.highlight .w {
  color: #bbb;
} /* Text.Whitespace */
.highlight .mf {
  color: #f60;
} /* Literal.Number.Float */
.highlight .mh {
  color: #f60;
} /* Literal.Number.Hex */
.highlight .mi {
  color: #f60;
} /* Literal.Number.Integer */
.highlight .mo {
  color: #f60;
} /* Literal.Number.Oct */
.highlight .sb {
  color: #c30;
} /* Literal.String.Backtick */
.highlight .sc {
  color: #c30;
} /* Literal.String.Char */
.highlight .sd {
  color: #c30;
  font-style: italic;
} /* Literal.String.Doc */
.highlight .s2 {
  color: #c30;
} /* Literal.String.Double */
.highlight .se {
  color: #c30;
} /* Literal.String.Escape */
.highlight .sh {
  color: #c30;
} /* Literal.String.Heredoc */
.highlight .si {
  color: #a00;
} /* Literal.String.Interpol */
.highlight .sx {
  color: #c30;
} /* Literal.String.Other */
.highlight .sr {
  color: #3aa;
} /* Literal.String.Regex */
.highlight .s1 {
  color: #c30;
} /* Literal.String.Single */
.highlight .ss {
  color: #fc3;
} /* Literal.String.Symbol */
.highlight .bp {
  color: #366;
} /* Name.Builtin.Pseudo */
.highlight .vc {
  color: #033;
} /* Name.Variable.Class */
.highlight .vg {
  color: #033;
} /* Name.Variable.Global */
.highlight .vi {
  color: #033;
} /* Name.Variable.Instance */
.highlight .il {
  color: #f60;
} /* Literal.Number.Integer.Long */

.css .o,
.css .o + .nt,
.css .nt + .nt {
  color: #999;
}

/*
  Color Scheme
*/

$color__background-content: #fff !default; // posts, pages and sidebar background color.
$color__background-body: #e4f0f6 !default; // body background color
$color__text: #000112 !default; // body text color

$color__link: #4e9dd8 !default; // color for links and accents
$color__link-hover: #2361a7 !default; // alternate color for active or focused links and accents

$color__details: #939496 !default; // color for details such as borders and shadows
$color__details-lighter: #f0f1f4 !default; // lighter alternative for details
$color__details-darker: #6d6e70 !default; // darker alternative for details

/*
  Typography
*/

$font__body: "Avenir Next", Corbel, "Fira Sans", "Trebuchet MS", Ubuntu, sans-serif !default; // used for the main, body typography
$font__headings: $font__body !default; // used for headings and feature elements
$font__code: "Cascadia Code", SFMono-Regular, Menlo, Consolas, "Fira Mono", "DejaVu Sans Mono", monospace !default; // used for pre-formatted text and code.


/*
  Layout
*/
$layout__container-width: 1180px !default; // the width of the main content (content + sidebar)
$layout__gutter: 2rem !default; // the spacing unit between elements.

:root {
  --color-bg: #{$color__background-content};
  --color-body: #{$color__background-body};
  --color-text: #{$color__text};

  --color-link: #{$color__link};
  --color-link-hover: #{$color__link-hover};

  --color-details: #{$color__details};
  --color-details-lighter: #{$color__details-lighter};
  --color-details-darker: #{$color__details-darker};

  --font: #{$font__body};
  --font-headings: #{$font__headings};
  --font-code: #{$font__code};

  --container: #{$layout__container-width};
  --gap: #{$layout__gutter};
}

@import "steve/base", "steve/layout", "steve/syntax";

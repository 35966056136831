@font-face {
  font-family: 'SPORE';
  src: url('/assets/fonts/Spore-Bold.woff2') format('woff2'),
      url('/assets/fonts/Spore-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SPORE';
  src: url('/assets/fonts/Spore-Light.woff2') format('woff2'),
      url('/assets/fonts/Spore-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SPORE';
  src: url('/assets/fonts/Spore-Regular.woff2') format('woff2'),
      url('/assets/fonts/Spore-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

